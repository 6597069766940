$main: #ededee;

.App {
    background: $main;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    font-family: 'Assistant', sans-serif;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    direction: rtl;
    width: 100vw;
    overflow-x: hidden;
}
a {
    text-decoration: none;
    color: black;
}
.header {
    width: 100%;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    background: $main;
    z-index: 100;
    box-shadow: 5px 0 8px 0 rgba(100,100,100,0.4);

    .items {
        display: flex;
        width: 100%;
        max-width: 1200px;
        user-select: none;
    }
    .item {
        height: 61px;
        padding: 0 40px;
        font-weight: bold;
        cursor: pointer;
        display: flex;
        align-items: center;
        &:hover {
            background: rgba(100,100,100, .1)
        }
        img {
            margin-left: 10px;
        }
        @media screen and (max-width: 768px) {
            padding: 0 10px;
        }
    }
    .hamburger {
        display: none;
        height: 61px;
        align-items: center
    }
    .items-wrapper {
        display: flex;
    }
    @media (max-width:1200px) {
        padding: 0 20px;
    }
    @media (max-width:768px) {
        .items-wrapper {
            flex-direction: column;
        }
        .hamburger {
            display: flex;
        }
        .items {
            flex-direction: column;
        }
        .items:not(.open) {
            .item {
                display: none;
            }
        }
    }
}

.header-images {
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    bottom: 61px;
    height: calc(100vh - 61px - 61px);
    padding-top: 30px;
    // position: relative;
    .white-overlay {
        position: fixed;
        top: 0;
        right: 0;
        background: rgba(237,237,237,0.5);
        height: 100vh;
        max-height: calc(100vh);
        width: 100vw;
        font-weight: bold;
        font-size: 4.5em;
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgb(58, 58, 58);
        backdrop-filter: blur(5px);
        img {
            margin-left: 30px;
        }
        @media screen and (max-width: 1200px) {
            font-size: 2em;
            img {
                margin-left: 15px;
                height: 70px;
            }
        }
    }
    picture {
        display: flex;
        justify-content: center;
        img {
            max-height: 600px;
            max-width: 230px;
        }
    }
    @media (max-width:1200px) {
        picture {
            min-width: 100%;
            img {
                max-width: 350px;
            }
        }
        picture:not(.on-mobile) {
            display: none;
        }
    }
}
.whatsapp {
    fill: #12cf12;
    position: fixed;
    right: 20px;
    bottom: 20px;
    cursor: pointer;
    transform-origin: bottom right;
    transform: scale(2);
    z-index: 2;
    transition: .2s ease-out all;
    &:hover {
        fill: #12a012;
    }
}

.content-wrapper {
    z-index: 1;
    margin-top: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    background: $main;
    box-shadow: 5px 0 8px 0 rgba(100, 100, 100, 0.4) inset;
    > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 30px;
        max-width: 1200px;
        @media screen and (max-width: 768px) {
            padding: 0;
        }
    }
    h2 {
        @media (max-width:1200px) {
            padding-right: 20px;
        }
    }
}
.video-wrapper {
    display: flex;
    margin-top: 100px;
    width: 100%;
    max-width: 1200px;
    justify-content: space-between;
    align-items: center;
    .desc {
        display: flex;
        flex-direction: column;
        padding: 10px;
        h2 {
            margin-top: 20px;
        }
        p {
            margin-bottom: 0;
        }
    }
    video {
        max-width: 400px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.8);
    }
    @media (max-width:768px) {
        flex-direction: column;
        margin-top: 100px;
        padding: 0 20px;
        video {
            max-width: 250px;
        }
        .desc {
            margin-bottom: 50px;
        }
    }
}
.content {
    margin-top: 100px;
    font-size: 2em;
    width: 100%;
    max-width: 1200px;
}
.products {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 40px;
    grid-gap: 10px;
    .item {
        width: 350px;
        height: 450px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: black;
        font-size: 1.2em;
        box-shadow: 0 0 99px 0 rgba(0, 0, 0, 0.2) inset;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        &:hover {
            >span {
                transform: scale(1.05);
            }
        }
        >span {
            transition: .2s ease-out all;
            background: rgba(255,255,255,0.7);
            position: relative;
            padding: 20px;
            width: 100%;
            text-align: center;
            backdrop-filter: blur(2px);
        }
        img.background {
            position: absolute;
            height: 100%;
            z-index: -1;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    @media (max-width: 1200px) {
        flex-direction: column;
        align-items: center;
        .item {
            margin-bottom: 40px;
        }
    }
}
.contact-row {
    margin-top: 200px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 390px;
        img {
            max-width: 390px;
            max-height: 350px;
        }
    }
    @media (max-width:1200px) {
        flex-direction: column;
        .item {
            margin-bottom: 40px;
        }
    }
}
.footer {
    width: 100%;
    padding: 20px;
    font-size: .8em;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    margin-top: 50px;
}